<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-container
      fluid
      class="px-6"
    >
      <v-tabs
        class="mt-6"
        v-model="tab"
        background-color="deep-purple accent-4"
        center-active
        dark
      >
        <v-tab href="#general-info"
          >{{ $t("products.general_info") }}
          <v-icon
            color="warning"
            right
            v-if="general_info_valid"
          >
            mdi-alert-circle-outline
          </v-icon></v-tab
        >
        <v-tab href="#pricing"
          >{{ $t("products.pricing") }}
          <v-icon
            color="warning"
            right
            v-if="pricing_valid"
          >
            mdi-alert-circle-outline
          </v-icon></v-tab
        >
        <v-tab href="#marketing-info"
          >{{ $t("products.marketing_info") }}
          <v-icon
            color="warning"
            right
            v-if="marketing_info_valid"
          >
            mdi-alert-circle-outline
          </v-icon>
        </v-tab>
      </v-tabs>
      <products-view-loader v-if="!loaded" />
      <v-tabs-items
        v-if="loaded"
        v-model="tab"
      >
        <v-tab-item
          :key="1"
          :value="'general-info'"
          eager
        >
          <!-- Genenral Information Section -->
          <div
            class="d-flex flex-column flex-md-row"
            style="gap: 100px"
          >
            <!-- General Information - Left Section -->
            <div
              class="flex-grow-1 mt-10"
              style="min-width: 500px; max-width: 50%"
            >
              <div>
                <h4 class="mb-6">{{ $t("product_info") }}</h4>
                <v-select
                  outlined
                  dense
                  :label="$t('group_type')"
                  item-value="value"
                  item-text="name"
                  @change="updatePrices"
                  :rules="[rules.not_empty]"
                  :items="groupTypes"
                  v-model="product.type"
                >
                </v-select>
                <v-text-field
                  outlined
                  dense
                  :label="$t('package_bundle_name')"
                  v-model="product.name"
                  required
                  @blur="updateNameMarketing(product.name)"
                  :rules="[
                    rules.field_required('name', product.name),
                    rules.field_length_between('name', 4, 60, product.name),
                  ]"
                >
                  <template v-slot:append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-information-outline </v-icon>
                      </template>
                      {{ $t("package_bundle_name") }}
                    </v-tooltip>
                  </template>
                </v-text-field>
                <div
                  class="d-flex"
                  v-for="(barcode, index) in product.barcodes"
                  v-bind:key="'barcode' + index"
                >
                  <v-text-field
                    outlined
                    dense
                    :label="$t('barcode')"
                    v-model="product.barcodes[index].code"
                    @input="debounceCheckBarcode($event, index)"
                    v-bind:loading="checkingBarcode"
                    :rules="[
                      rules.field_required(
                        'barcode',
                        product.barcodes[index].code
                      ),
                    ]"
                    v-on:keypress="NumbersOnly"
                    append-icon="mdi-reload"
                    @click:append="() => generateBarCode(index)"
                  >
                    <v-icon
                      slot="prependIcon"
                      large
                      color="primary"
                      >comment</v-icon
                    >
                    <template
                      v-slot:append-outer
                      v-if="index > 0"
                    >
                      <v-icon
                        color="red"
                        @click="() => removeBarCode(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-text-field>
                </div>
                <v-btn
                  text
                  @click="addBarCode"
                >
                  <span class="purple--text">{{ $t("add_barcode") }}</span>
                </v-btn>
              </div>
              <div class="mt-6">
                <h4 class="mb-6">{{ $t("categorization") }}</h4>
                <v-autocomplete
                  outlined
                  dense
                  :items="shelves"
                  v-model="currentShelf"
                  @change="shelfChanged($event)"
                  item-value="id"
                  item-text="name"
                  :label="$t('shelf')"
                  :rules="[(v) => !!v || 'Shelf is required']"
                >
                </v-autocomplete>
                <v-autocomplete
                  outlined
                  dense
                  :items="currentCategories"
                  v-model="currentRoot"
                  @change="rootChanged($event)"
                  item-value="id"
                  item-text="name"
                  :label="$t('category_parent')"
                  :rules="[
                    rules.field_required('category_parent', currentRoot),
                  ]"
                >
                </v-autocomplete>
                <v-autocomplete
                  outlined
                  dense
                  :items="currentSubCategories"
                  v-model="product.category_id"
                  item-value="id"
                  item-text="name"
                  :label="$t('category')"
                  :rules="[
                    rules.field_required('category', product?.category_id),
                  ]"
                  @change="categoryChanged($event)"
                >
                </v-autocomplete>
                <v-combobox
                  outlined
                  dense
                  class="mb-6"
                  :label="$t('brand')"
                  v-model="product.brand"
                  :items="brands.map((brand) => brand.name)"
                ></v-combobox>
              </div>
              <div>
                <h4 class="mb-3">
                  {{ $t("grouped_product_info", { type: product.type }) }}
                </h4>
                <v-row class="mt-0">
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      :label="$t('item_net_weight')"
                      v-model="product.product_info.item_net_weight"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    >
                    </v-text-field>
                    <v-select
                      outlined
                      dense
                      hide-details
                      style="max-width: 100px"
                      :label="$t('unit')"
                      :items="weightUnits"
                      v-model="product.product_info.item_net_weight_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_net_weight ||
                          !!v ||
                          'Unit required',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      :label="$t('item_gross_weight')"
                      v-model.number="product.product_info.item_gross_weight"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    >
                    </v-text-field>
                    <v-select
                      outlined
                      dense
                      hide-details
                      style="max-width: 100px"
                      :label="$t('unit')"
                      :items="weightUnits"
                      v-model="product.product_info.item_gross_weight_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_gross_weight ||
                          !!v ||
                          'Unit required',
                      ]"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      class="mb-6"
                      :label="$t('item_net_volume')"
                      v-model.number="product.product_info.item_net_volume"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    >
                    </v-text-field>
                    <v-select
                      outlined
                      dense
                      hide-details
                      class="mb-6"
                      style="max-width: 100px"
                      :label="$t('unit')"
                      :items="volumeUnits"
                      v-model="product.product_info.item_net_volume_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_net_volume ||
                          !!v ||
                          'Unit required',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      class="mb-6"
                      :label="$t('item_gross_volume')"
                      v-model.number="product.product_info.item_gross_volume"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    ></v-text-field>
                    <v-select
                      outlined
                      dense
                      hide-details
                      class="mb-6"
                      style="max-width: 100px"
                      :label="$t('unit')"
                      v-model="product.product_info.item_gross_volume_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_gross_volume ||
                          !!v ||
                          'Unit required',
                      ]"
                      :items="volumeUnits"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>
            <!-- /General Information - Left Section -->
            <!-- General Information - Right Section -->
            <div
              div
              class="flex-grow-1 mt-10"
              style="min-width: 400px"
            >
              <h4 class="mb-3">
                {{
                  product.type == "pack"
                    ? $t("setup.grouped_products.package_item")
                    : $t("setup.grouped_products.bundle_items")
                }}
              </h4>
              <v-card
                v-if="product.type === 'pack' && editedPackItem.qte_in_bundle"
                elevation="6"
                class="ma-2 mt-6"
              >
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="7">
                    <p class="font-weight-bold">Product</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="font-weight-bold">Qty</p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="justify-left align-center my-2">
                  <v-col
                    cols="3"
                    class="d-flex justify-center align-center"
                  >
                    <v-img
                      v-if="editedPackItem?.images.length > 0"
                      :src="editedPackItem?.images[0]?.url"
                      max-height="100"
                      max-width="100"
                    />
                    <v-img
                      v-else
                      src="@/assets/product.png"
                      max-height="50"
                      max-width="50"
                    />
                  </v-col>
                  <v-col cols="7">
                    <h4>{{ editedPackItem?.name }}</h4>
                    <p>
                      ID:&nbsp;{{ editedPackItem?.code }} -
                      {{ $t("barcode") + ": " + editedPackItem?.barcode }}
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <p>{{ editedPackItem.qte_in_bundle }}</p>
                  </v-col>
                </v-row>
                <v-divider
                  inset
                  my-6
                ></v-divider>
              </v-card>
              <v-card
                v-if="product.type === 'bundle' && editedBundleItems?.length"
                elevation="6"
                class="ma-2 mt-6"
              >
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="7">
                    <p class="font-weight-bold">Product</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="font-weight-bold">Qty</p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <template v-for="(item, i) in editedBundleItems">
                  <template>
                    <v-row
                      class="justify-left align-center my-2"
                      mb-3
                      :key="`bundleItem-${i}`"
                    >
                      <v-col cols="3">
                        <div class="d-flex justify-center">
                          <v-img
                            v-if="item?.images.length > 0"
                            :src="item?.images[0]?.url"
                            max-height="75"
                            max-width="75"
                          />
                          <v-img
                            v-else
                            src="@/assets/product.png"
                            max-height="50"
                            max-width="50"
                          />
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <h4>{{ item?.name }}</h4>
                        <p>
                          ID:&nbsp;{{ item?.code }} -
                          {{ $t("barcode") + ": " + item?.barcode }}
                        </p>
                      </v-col>
                      <v-col cols="2">
                        <p class="font-weight-bold">{{ item.qte_in_bundle }}</p>
                      </v-col>
                    </v-row>
                    <v-divider :key="`bundleItem-${i}-divider`"></v-divider>
                  </template>
                </template>
              </v-card>
              <div class="d-flex">
                <v-btn
                  text
                  @click="showAddGroupedProductItemsModal"
                >
                  <span class="purple--text">{{
                    product.type == "pack"
                      ? editedPackItem?.qte_in_bundle
                        ? $t("setup.grouped_products.change_item")
                        : $t("setup.grouped_products.add_item")
                      : editedBundleItems?.length
                      ? $t("setup.grouped_products.change_items")
                      : $t("setup.grouped_products.add_items")
                  }}</span>
                </v-btn>
                <edit-pack-item :updatePrices="updatePrices" />
                <edit-bundle-items :updatePrices="updatePrices" />
              </div>
            </div>
            <!-- General Information - Right Section -->
          </div>
          <!-- /General Information Section -->
        </v-tab-item>
        <v-tab-item
          :key="2"
          :value="'pricing'"
          eager
        >
          <div
            class="mt-10 d-flex"
            style="gap: 50px"
          >
            <div class="flex-grow-1">
              <h4 class="mb-6">{{ $t("product_info") }}</h4>
              <v-text-field
                filled
                :label="$t('setup.grouped_products.calculated_selling_price')"
                v-model.number="product.purchase_price_ht"
                :rules="[
                  rules.field_required_ignores(
                    'purchase_price_ht',
                    product?.purchase_price_ht,
                    [0]
                  ),
                ]"
                v-on:keypress="NumbersOnly"
              ></v-text-field>
              <v-select
                filled
                :label="$t('tva')"
                v-model="product.tva"
                :items="VATs"
                @change="tvaChanged($event)"
                :rules="[rules.percentage]"
              ></v-select>
              <v-text-field
                filled
                v-model="product.price"
                :label="$t('selling_price_incl_tva')"
                :rules="[
                  rules.field_required(
                    'selling_price_incl_tva',
                    product?.price
                  ),
                ]"
                v-on:keypress="NumbersOnly"
              ></v-text-field>
            </div>
            <div class="flex-grow-1">
              <h4 class="mt-2">{{ $t("pricing_details") }}</h4>
              <div class="mt-8 font-weight-medium">
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("price_ht") }}</span>
                  <span>
                    {{
                      product.price &&
                      product.purchase_tva != undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency,
                      }).format(product.price / (1 + product.tva / 100))
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("TVA") }}</span>
                  <span>{{
                    new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: user.currency ?? "DZD",
                    }).format(product.price - product.price_ht)
                  }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{
                    $t("setup.grouped_products.price_difference")
                  }}</span>
                  <span>{{ diffrencePercentage }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{
                    $t("setup.grouped_products.price_difference_value")
                  }}</span>
                  <span>{{ priceDifferenceValue }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          disabled
          :key="3"
          :value="'marketing-info'"
          eager
        >
          <div
            class="mt-10"
            style="max-width: 700px"
          >
            <h4>{{ $t("marketing_details") }}</h4>
            <div class="mt-8 font-weight-medium">
              <v-text-field
                filled
                :label="$t('marketing_designation_*')"
                v-model="product.name_marketing"
                required
                :rules="[
                  rules.field_length_between(
                    'marketing_designation',
                    4,
                    80,
                    product?.name_marketing
                  ),
                ]"
              ></v-text-field>
              <p class="d-flex justify-space-between align-center my-2">
                <span>Express Status</span>
                <v-switch v-model="product.express_status"></v-switch>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="mt-2">
                <span>{{ $t("products.short_description") }}</span>
                <v-textarea
                  outlined
                  class="my-4"
                  v-model="product.short_description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="my-2">
                <span>{{ $t("description") }}</span>
                <vue-editor
                  class="my-4"
                  v-model="product.description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="my-2">
                <span>{{ $t("special-categories") }}</span>
                <v-autocomplete
                  class="mt-4"
                  filled
                  v-model="product.special_category_ids"
                  :items="leafSpecialCategories"
                  item-value="id"
                  item-text="name"
                  :label="$t('special_sub_category_*')"
                  multiple
                  chips
                >
                </v-autocomplete>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
            </div>
            <div
              class="mt-2"
              v-if="product?.type == 'pack'"
            >
              <span>{{ $t("quantity") }}</span>
              <v-text-field
                class="flex-grow-0 mt-3"
                outlined
                dense
                hide-details
                type="number"
                hide-spin-buttons
                v-model="product.max_order_qte"
                :label="$t('setup.grouped_products.max_order_quantity')"
                :rules="[]"
              />
            </div>
            <div class="d-flex justify-space-between align-center my-2">
              <span>{{ $t("product_image") }}</span>
              <file-preview ref="filePreview"></file-preview>
            </div>
            <hr style="border-top: 1px solid #d3d3d3" />
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div
        class="mt-10"
        v-if="loaded"
      >
        <v-btn
          depressed
          color="primary"
          class="me-2"
          v-on:click="submit"
          :loading="isLoading"
          :disabled="isLoading"
        >
          {{ $t("save_changes") }}
        </v-btn>
        <v-btn
          text
          @click="returnToList()"
        >
          {{ $t("cancel") }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import { GroupedProduct, ProductInfo } from "@/classes/packAndBundle.class";
import { validationRules } from "@/mixins/validationRules";
import _, { debounce } from "lodash";
import FilePreview from "./FilePreview";
import productService from "@/store/services/product-service";
import Vue from "vue";
import ProductsViewLoader from "@/views/dashboard/rebrics/setup/products/ProductsViewLoader.vue";

export default {
  mixins: [validationRules],
  components: {
    VueEditor,
    FilePreview,
    ProductsViewLoader,
    editPackItem: () => import("./dialogs/EditPackageItemDialog.vue"),
    editBundleItems: () => import("./dialogs/EditBundleItemsDialog.vue"),
  },
  data() {
    return {
      tab: null,
      valid: false,
      loaded: false,
      product: new GroupedProduct(),
      productEntity: null,
      currentRoot: null,
      currentShelf: null,
      currentCategories: [],
      currentSubCategories: [],
      content: "<p>Product Description</p>",
      volumeUnits: ["ml", "cl", "L", "mm3", "cm3", "m3"],
      weightUnits: ["g", "kg"],
      general_info_valid: false,
      pricing_valid: false,
      marketing_info_valid: false,
      groupTypes: [
        {
          name: "Package",
          value: "pack",
        },
        {
          name: "Bundle",
          value: "bundle",
        },
      ],
      checkingBarcode: false,
      isLoading: false,
      is_loading_hubs: false,
      search_hub_text: "",
    };
  },
  computed: {
    ...mapGetters({
      isLoadingShelves: "categories/isLoadingCategories",
      shelves: "categories/listShelves",
      categories: "categories/listRoots",
      leafs: "categories/listLeafs",
      brands: "brands/activeBrands",
      leafSpecialCategories: "specialCategories/listLeafs",
      deliveryTypes: "deliveryTypes/list",
      isDeliveryTypesLoading: "deliveryTypes/isLoading",
      priceSettings: "settings/priceSettings",
      editedBundleItems: "packsAndBundles/editedBundleItems",
      hubs: "hubs/listHubs",
      user: "users/editedUser",
    }),

    VATs() {
      return this.priceSettings.vat_array?.map((vat) => ({
        text: vat + "%",
        value: vat,
      }));
    },
    editedBundleItems: {
      get() {
        return this.$store.state.packsAndBundles.editedBundleItems;
      },
      set(items) {
        this.$store.commit("packsAndBundles/SET_EDITED_BUNDLE_ITEMS", items);
      },
    },
    editedPackItem: {
      get() {
        return this.$store.state.packsAndBundles.editedPackItem;
      },
      set(value) {
        this.$store.commit("packsAndBundles/SET_EDITED_PACK_ITEM", value);
      },
    },
    diffrencePercentage() {
      const product = this.product;
      const purchasePriceHt = product.purchase_price_ht;

      return purchasePriceHt == 0 || product.price == 0
        ? "-"
        : `${(
            ((product.price - purchasePriceHt) * 100) /
            purchasePriceHt
          ).toFixed(2)} %`;
    },

    priceDifferenceValue() {
      const product = this.product;
      const purchasePriceHt = product.purchase_price_ht;

      return purchasePriceHt == 0 || product.price == 0
        ? "-"
        : new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: this.user.currency,
          }).format(this.product.price - this.product.purchase_price_ht);
    },
  },
  mounted() {
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },
  methods: {
    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;
      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
    showAddGroupedProductItemsModal() {
      this.product.type == "pack"
        ? this.$store.dispatch("packsAndBundles/openEditPackageItemForm")
        : this.$store.dispatch("packsAndBundles/openEditBundlesItemsForm");
    },
    loadProductTypes() {},
    loadShelves() {
      return this.$store.dispatch("categories/listShelves");
    },
    loadCategories() {
      return this.$store.dispatch("categories/listRoots");
    },
    loadSubCategories() {
      return this.$store.dispatch("categories/listLeafs");
    },
    loadBrands() {
      return this.$store.dispatch("brands/list");
    },
    loadDeliveryTypes() {
      return this.$store.dispatch("deliveryTypes/list");
    },
    loaderPriceSettings() {
      return this.$store.dispatch("settings/getPriceSettings");
    },
    init() {
      this.loadProductTypes();
      this.loadShelves();
      this.loadCategories();
      this.loadSubCategories();
      this.loadBrands();
      this.loadDeliveryTypes();
      this.loaderPriceSettings();
      this.$store.dispatch("specialCategories/listLeafs");
      this.$store.dispatch("packsAndBundles/resetItems");
    },
    returnToList() {
      this.$swal({
        icon: "question",
        text: "Product won't be saved? Are you sure?",
        showConfirmButton: false,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
          this.$router.push({ name: "setup.grouped_products.index" });
        }
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async saveImage() {
      const image = this.$refs.filePreview?.$data.image;
      if (image) {
        const formData = new FormData();
        formData.append("image_file", image);
        await productService.saveImage(this.product, formData);
      }
    },
    // returns true if object is empty
    empty(object) {
      return !Object.keys(object).length;
    },
    async submit() {
      this.isLoading = true;
      if (this.validate()) {
        try {
          if (
            this.product?.type === "pack" &&
            !this.empty(this.editedPackItem)
          ) {
            this.product.bundle_items = [
              {
                id: this.editedPackItem.id,
                qte_in_bundle: this.editedPackItem.qte_in_bundle,
              },
            ];
          } else if (
            this.product?.type === "bundle" &&
            !this.empty(this.editedBundleItems)
          ) {
            this.product.bundle_items = this.editedBundleItems.map((item) => {
              return { id: item.id, qte_in_bundle: item.qte_in_bundle };
            });
            const propsToDelete = [
              "hubs",
              "category",
              "image",
              "images",
              "lots",
              "main_provider",
              "provider",
              "provider_ids",
              "provider_status",
              "providers",
              "secondary_providers",
              "special_categories",
            ];
            propsToDelete.forEach((prop) => {
              if (Object.prototype.hasOwnProperty.call(this.product, "name")) {
                delete this.product[prop];
              }
            });
          } else {
            this.$swal(
              "",
              this.$t(
                this.product.type === "pack"
                  ? "select_product_for_pack"
                  : "select_at_least_product_for_bundle"
              ),
              "error"
            );
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          const savedProduct = await productService.storeGroupedProduct(
            this.product
          );
          if (savedProduct) {
            this.product.id = savedProduct.id;
            this.saveImage();
            this.$swal(
              "",
              this.$t("setup.grouped_products.saved_success"),
              "success"
            );
            this.$router.push("/setup/packs-and-bundles");
          }
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          // this.formErrors = error.response.data.errors;
          this.is_loading_save = false;
        }
      } else {
        this.general_info_valid = !!this.$el
          ?.querySelector("#general-info")
          ?.querySelector(".error--text");
        this.pricing_valid = !!this.$el
          ?.querySelector("#pricing")
          ?.querySelector(".error--text");
        this.marketing_info_valid = !!this.$el
          ?.querySelector("#marketing-info")
          ?.querySelector(".error--text");
        this.isLoading = false;
      }
    },
    calculateMarge() {
      const purchase_price_ttc =
        this.product.purchase_price_ht * (1 + this.product?.purchase_tva / 100);
      return Number(
        (100 * (this.product.price - purchase_price_ttc)) / purchase_price_ttc
      ).toFixed(2);
    },
    async generateBarCode(index) {
      if (this.checkingBarcode) return;
      this.checkingBarcode = true;
      if (!this.product.barcodes[index].code) {
        const generatedBarcode = await productService.generateBarcode();
        this.product.barcodes[index].code = parseInt(generatedBarcode);
      }
      this.checkingBarcode = false;
    },
    addBarCode() {
      if (this.product.barcodes.length >= 3) {
        this.$swal("", "Barcode limit reached", "info");
      } else {
        this.product.barcodes.push({
          is_main: false,
          code: null,
        });
      }
    },
    removeBarCode(barcodeIndex) {
      this.product.barcodes = this.product.barcodes.filter(
        (value, index) => index !== barcodeIndex
      );
    },
    shelfChanged(value) {
      this.currentRoot = null;
      this.currentCategories = this.categories.filter(
        (category) => category.parent_id === value
      );
    },
    rootChanged(value) {
      this.product.category_id = null;
      this.currentSubCategories = this.leafs.filter(
        (leaf) => leaf.parent_id === value
      );
    },
    categoryChanged(value) {
      this.$store.commit("packsAndBundles/SET_SELECTED_CATEGORY", value);
    },
    tvaChanged() {
      this.product.price_ht =
        this.product.price - this.product.price * (this.product.tva / 100);
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    debounceCheckBarcode: _.debounce(function (e, index) {
      this.checkBarCode(e, index);
    }, 1000),
    checkBarCode(event, index) {
      this.checkingBarcode = true;
      setTimeout(() => {
        console.log("TODO: Check the barcode", event, index);
        this.checkingBarcode = false;
      }, 2000);
    },
    updateNameMarketing(name) {
      if (!this.product.name_marketing)
        Vue.set(this.product, "name_marketing", name);
    },
    calcPackagePrice(ht, tva) {
      let purchase_price_ht = ht ?? this.editedPackItem?.purchase_price_ht ?? 0;
      const packageTva = tva ?? this.product?.tva;
      if (this.editedPackItem?.price_ht && this.editedPackItem?.qte_in_bundle) {
        purchase_price_ht = this.product.purchase_price_ht = (
          this.editedPackItem?.price_ht * this.editedPackItem?.qte_in_bundle
        ).toFixed(2);
      }
      const result =
        parseFloat(purchase_price_ht) +
        parseFloat(purchase_price_ht) * (parseFloat(packageTva) / 100);

      this.product.price = Math.round(result * 100) / 100;
    },
    calcBundlePrice() {
      if (this.editedBundleItems?.length <= 0) {
        return;
      }
      const sum = this.editedBundleItems
        .map((item) => {
          return parseFloat(item.price_ht * item.qte_in_bundle);
        })
        .reduce((a, b) => a + b, 0);
      const purchase_price_ht = sum < 0 ? 0 : sum.toFixed(2);
      this.product.purchase_price_ht = purchase_price_ht;
      const result =
        parseFloat(purchase_price_ht) +
        parseFloat(purchase_price_ht) * (parseFloat(this.product?.tva) / 100);

      this.product.price = Math.round(result * 100) / 100;
    },
    updatePrices() {
      console.log(this.product?.type);
      if (this.product?.type === "pack") {
        this.calcPackagePrice();
      }
      if (this.product?.type === "bundle") {
        this.calcBundlePrice();
      }
    },
    updateCategories(product) {
      if (product?.category_id) {
        const currentCategoryObject = this.leafs.find(
          (category) => category.id == product?.category_id
        );
        this.currentCategory = currentCategoryObject;
        const root = this.categories.find(
          (category) => category.id == currentCategoryObject?.parent_id
        );
        this.currentRoot = root;
        const shelf = this.shelves.find((shelf) => shelf.id == root?.parent_id);
        this.currentShelf = shelf;
        this.shelfChanged(shelf?.id);
        this.currentRoot = root;
        this.rootChanged(root?.id);
        this.product.category_id = currentCategoryObject?.id;
      }
    },
    updateBrand(value) {
      this.product.brand = value;
    },
  },
  watch: {
    search_hub_text: function (search) {
      this.search_hub(search);
    },
    editedPackItem(value) {
      this.updateCategories(value);
      if (value.brand?.name) {
        this.updateBrand(value.brand.name);
      }
    },
  },
  async created() {
    this.init();
    if (this.$route.params.id) {
      const product = await productService.show({
        id: this.$route.params.id,
      });
      this.updateCategories(product);
      this.categoryChanged(product.category.id);
      this.product = {
        ...product,
        brand: product.brand?.name,
        product_info: product.product_info ?? new ProductInfo(),
        bundle_items:
          product.type !== "bundle" ? this.product.bundle_items : null,
      };
      if (product.type === "pack") {
        this.editedPackItem = product?.bundle_items[0];
      }
      if (product.type === "bundle") {
        this.editedBundleItems = product.bundle_items.map((bundle_item) => {
          return {
            ...bundle_item,
          };
        });
      }
    }
    this.loaded = true;
  },
};
</script>